
import { Component, Vue } from 'vue-property-decorator'
import { EnginneeringInfo } from '@/types/engineering'
import { DicInfo, DicList } from '../../types/common'

@Component({
  name: 'List'
})
export default class List extends Vue {
  private searchInfo = {
    engineeringName: '',
    engineeringStage: '',
    contractStartDate: '',
    contractEndDate: ''
  }

  private loading = false
  private tableData: EnginneeringInfo[]= []
  page = 1
  size = 10
  total = 0
  private dateRange: Array<string> = []
  engineeringStageList: DicInfo[] = []

  created () {
    this.getData()
    this.getEngineeringStage()
  }

  // 获取工程阶段列表
  getEngineeringStage () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'engineeringStage' }).then(res => {
      this.engineeringStageList = res.engineeringStage
    })
  }

  // 获取工程列表
  getData () {
    this.loading = true
    if (this.dateRange) {
      this.searchInfo.contractStartDate = this.dateRange[0] || ''
      this.searchInfo.contractEndDate = this.dateRange[1] || ''
    } else {
      this.searchInfo.contractStartDate = ''
      this.searchInfo.contractEndDate = ''
    }
    this.$axios.get<{ total: number; list: Array<EnginneeringInfo> }>(this.$apis.engineering.selectEngineeringInfoByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增工程
  onAdd () {
    this.$router.push({ name: 'AddList' })
  }

  // 编辑工程
  onUpdate (id: string) {
    this.$router.push({ name: 'UpdateList', params: { id } })
  }

  // 查看工程详情
  onDetail (id: string) {
    this.$router.push({ name: 'DetailList', params: { id } })
  }

  // 删除工程
  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.engineering.deleteEngineeringInfo, {
          infoId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.onSearch()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
